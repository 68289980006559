import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTaskStatusStore = defineStore('tasksStatus', () => {
  //TODO: replace individual task var to map or list of tasks
  //Note: daily is currently being set by each tab but is not being used
  const share = ref({ status: false, daily: false })
  const follow = ref({ status: false, daily: false })
  const followJustJoined = ref({ status: false, daily: false })
  const followByUsername = ref({ status: false, daily: false })
  const unfollow = ref({ status: false, daily: false })
  const bulkPrice = ref({ status: false, daily: false })
  const bulkOffer = ref({ status: false, daily: false })
  const autoOffer = ref({ status: false, daily: false })
  const scanCloset = ref({ status: false, daily: false })
  const calculateCloset = ref({ status: false, daily: false })
  const communityShare = ref({ status: false, daily: false })
  const activityReturner = ref({ status: false, daily: false })

  const changeShareStatus = (status: boolean) => {
    share.value.status = status
    taskStatusUpdated()
  }
  const changeShareDaily = (daily: boolean) => {
    share.value.daily = daily
  }
  const changeFollowStatus = (status: boolean) => {
    follow.value.status = status
    taskStatusUpdated()
  }
  const changeFollowDaily = (daily: boolean) => {
    follow.value.daily = daily
  }
  const changeFollowJustJoinedStatus = (status: boolean) => {
    followJustJoined.value.status = status
    taskStatusUpdated()
  }
  const changeFollowJustJoinedDaily = (daily: boolean) => {
    followJustJoined.value.daily = daily
  }
  const changeFollowByUsernameStatus = (status: boolean) => {
    followByUsername.value.status = status
    taskStatusUpdated()
  }
  const changeFollowByUsernameDaily = (daily: boolean) => {
    followByUsername.value.daily = daily
  }
  const changeUnfollowStatus = (status: boolean) => {
    unfollow.value.status = status
    taskStatusUpdated()
  }
  const changeUnfollowDaily = (daily: boolean) => {
    unfollow.value.daily = daily
  }
  const changeBulkPriceStatus = (status: boolean) => {
    bulkPrice.value.status = status
    taskStatusUpdated()
  }
  const changeAutoOfferStatus = (status: boolean) => {
    autoOffer.value.status = status
    taskStatusUpdated()
  }
  const changeBulkOfferStatus = (status: boolean) => {
    bulkOffer.value.status = status
    taskStatusUpdated()
  }
  const changeScanClosetStatus = (status: boolean) => {
    scanCloset.value.status = status
    taskStatusUpdated()
  }
  const changeCalculateClosetStatus = (status: boolean) => {
    calculateCloset.value.status = status
    taskStatusUpdated()
  }
  const changeCommunityShareStatus = (status: boolean) => {
    communityShare.value.status = status
    taskStatusUpdated()
  }
  const changeCommunityShareDaily = (daily: boolean) => {
    communityShare.value.daily = daily
  }
  const changeActivityReturner = (status: boolean) => {
    activityReturner.value.status = status
    taskStatusUpdated()
  }

  const taskStatusUpdated = () => {
    //handles any checks related to running tasks i.e. add a beforeunload handler in case a user redirects while a task is active
    if (isTaskRunning()) {
      window.onbeforeunload = () => {
        return 'Are you sure you want to leave this page?'
      }
      return
    }

    window.onbeforeunload = null
  }

  const isTaskRunning = () => {
    if (
      share.value.status ||
      follow.value.status ||
      followJustJoined.value.status ||
      followByUsername.value.status ||
      unfollow.value.status ||
      bulkPrice.value.status ||
      autoOffer.value.status ||
      bulkOffer.value.status ||
      scanCloset.value.status ||
      calculateCloset.value.status ||
      communityShare.value.status ||
      activityReturner.value.status
    )
      return true
    return false
  }

  return {
    share,
    follow,
    followJustJoined,
    followByUsername,
    unfollow,
    bulkPrice,
    autoOffer,
    bulkOffer,
    scanCloset,
    calculateCloset,
    communityShare,
    activityReturner,
    changeShareStatus,
    changeFollowStatus,
    changeFollowJustJoinedStatus,
    changeFollowByUsernameStatus,
    changeUnfollowStatus,
    changeBulkPriceStatus,
    changeAutoOfferStatus,
    changeBulkOfferStatus,
    changeScanClosetStatus,
    changeCalculateClosetStatus,
    changeUnfollowDaily,
    changeFollowByUsernameDaily,
    changeFollowJustJoinedDaily,
    changeFollowDaily,
    changeShareDaily,
    changeCommunityShareStatus,
    changeCommunityShareDaily,
    changeActivityReturner
  }
})
