<template>
    <TabNavigation :is-active="taskStatus.autoOffer.status
        || taskStatus.bulkOffer.status" />
    <LoadingScreen :isLoading="isLoading" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">

        <div class="p-4 rounded bg-gray-100">
            <div>
                <div class="flex items-center justify-between">
                    <h6 class="text-base font-bold">Auto Offer</h6>
                    <div class="flex items-center">
                        <InputSwitch input-id="autoOfferFunction" v-model="autoOfferFunction" onLabel="ON"
                            offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.autoOffer.status"
                            @change="onAutoOfferChange" />
                    </div>
                </div>
            </div>
            <div v-if="autoOfferFunction">
                <Card class="p-0 mt-3">
                    <template #content>
                        <div v-if="validations.has('autoOffer')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('autoOffer')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="flex items-center gap-x-3 mt-4">
                            <label for="sendOfferAfter" class=""> Send offer to new likers after
                            </label>
                            <InputNumber :min="1" :max="10" placeholder="0"
                                :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                                v-model="sendOfferAfter" inputId="sendOfferAfter" :useGrouping="true"
                                :disabled="taskStatus.autoOffer.status" />
                            <span>Minutes</span>
                        </div>
                        <div class="flex items-center gap-x-3 mt-4">
                            <label for="percentDiscount" class=""> Percent Discount </label>
                            <InputNumber :min="0" :max="100" placeholder="0"
                                :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                                v-model="percentDiscount" inputId="percentDiscount" :useGrouping="true"
                                :disabled="taskStatus.autoOffer.status" />
                            <span>%</span>
                        </div>
                        <div class="flex flex-col gap-y-4 mt-4 justify-center ">
                            <label for="shippingOffer" class=""> Shipping offer </label>
                            <div class="mx-auto">
                                <SelectButton :pt="shippingSelectLabelStyles" v-model="shippingOffer"
                                    :options="shippingOfferOptions" option-label="label" option-value="value"
                                    aria-labelledby="basic" :disabled="taskStatus.autoOffer.status" />
                            </div>
                        </div>
                        <div>
                            <Accordion class="mt-4" v-model:activeIndex="isAutoOfferExcludeByTypeEnabled">
                                <AccordionTab header="Exclude by Type">
                                    <div class="card flex justify-center">
                                        <div class="card flex flex-col flex-wrap gap-y-3">
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="inventoryList"
                                                    v-model="excludeStatus.inventoryList" onLabel="ON" offLabel="OFF"
                                                    class="col-span-1" :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'inventoryList'" class="hover:cursor-pointer col-span-5">
                                                    Exclude items using listing urls
                                                </label>
                                            </div>
                                            <div v-if="excludeStatus.inventoryList"
                                                class="card grid grid-cols-12 items-center">
                                                <Chips :pt="chipsListStyles" v-model="exemptInventoryList" separator=","
                                                    input-class="w-full p-3"
                                                    class=" col-span-12 grid grid-cols-12 p-2 border border-[#0070b7] rounded-lg"
                                                    placeholder="Copy product URL and paste it here"
                                                    :disabled="taskStatus.autoOffer.status" />
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="newListings" v-model="excludeStatus.newListings"
                                                    onLabel="ON" offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'newListings'" class="hover:cursor-pointer col-span-5">
                                                    Exclude new with tags items
                                                </label>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="boutiqueListings"
                                                    v-model="excludeStatus.boutiqueListings" onLabel="ON" offLabel="OFF"
                                                    class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'boutiqueListings'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Exclude boutique items
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            <Accordion class="" v-model:activeIndex="isAutoOfferExcludeByAgeEnabled">
                                <AccordionTab header="Exclude by Age">
                                    <div class="card flex justify-center">
                                        <div class="card flex flex-col flex-wrap gap-y-3">
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="itemsListedLately"
                                                    v-model="excludeStatus.itemsListedLately" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'itemsListedLately'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Exclude items listed lately
                                                </label>
                                            </div>
                                            <div v-if="excludeStatus.itemsListedLately"
                                                class="w-full flex items-center gap-4">
                                                <label for="listedLately" class="">Items Listed in the last </label>
                                                <InputNumber :min="1" placeholder="0"
                                                    :input-class="'w-[60px] text-sm p-3 border border-[#0070b7] rounded-lg '"
                                                    v-model="listedLately" inputId="listedLately" :useGrouping="true"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <span class="text-sm">days</span>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="onlyOldItems"
                                                    v-model="excludeStatus.onlyOldItems" onLabel="ON" offLabel="OFF"
                                                    class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'onlyOldItems'" class="hover:cursor-pointer col-span-5">
                                                    Only send offers for old items
                                                </label>
                                            </div>
                                            <div v-if="excludeStatus.onlyOldItems"
                                                class="w-full flex items-center gap-4">
                                                <label for="listingsOlderThan" class="text-sm">Items Listed </label>
                                                <InputNumber :min="1" placeholder="0"
                                                    :input-class="'w-[60px] text-sm p-3 border border-[#0070b7] rounded-lg '"
                                                    v-model="listingsOlderThan" inputId="listingsOlderThan"
                                                    :useGrouping="true" :disabled="taskStatus.autoOffer.status" />
                                                <span class="text-sm">days ago</span>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="priceRangeForListings"
                                                    v-model="excludeStatus.priceRangeForListings" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.autoOffer.status" />
                                                <label :for="'priceRangeForListings'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Only send offers on listings within a price range
                                                </label>
                                            </div>
                                            <div v-if="excludeStatus.priceRangeForListings" class="w-full">
                                                <div class="flex justify-between w-full">
                                                    <InputNumber v-model="priceRange[0]" :min="minPriceRange"
                                                        :max="maxPriceRange"
                                                        :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg mb-4 text-center'"
                                                        :disabled="taskStatus.autoOffer.status" />
                                                    <InputNumber v-model="priceRange[1]" :min="minPriceRange"
                                                        :max="maxPriceRange"
                                                        :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg mb-4 text-center'"
                                                        :disabled="taskStatus.autoOffer.status" />
                                                </div>
                                                <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles"
                                                    v-model="priceRange" range :step="1" :min="minPriceRange"
                                                    :max="maxPriceRange" class="mb-10"
                                                    :disabled="taskStatus.autoOffer.status" />
                                            </div>
                                        </div>
                                    </div>

                                </AccordionTab>

                            </Accordion>
                            <div class="flex justify-end items-center mt-2">
                                <Button v-if="taskStatus.autoOffer.status"
                                    @click="requireConfirmation('top', 'stopAutoOfferTask')"
                                    class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                                    Stop Current Task
                                </Button>
                                <Button v-else
                                    class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer text-sm hover:bg-white hover:text-[#0070b7] mt-2 mb-4"
                                    @click="validateAutoOfferRequest()">
                                    Start
                                    <div v-if="!authStore.hasActivePlan()"
                                        class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                        <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>
        </div>

        <div class="mt-4 p-4 rounded bg-gray-100">
            <div>
                <div class="flex items-center justify-between">
                    <h6 class="text-base font-bold">Bulk Offer</h6>
                    <div class="flex items-center">
                        <InputSwitch input-id="bulkOfferFunction" v-model="bulkOfferFunction" onLabel="ON"
                            offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.bulkOffer.status"
                            @change="onBulkOfferChange" />
                    </div>
                </div>
            </div>
            <div v-if="bulkOfferFunction">
                <Card class="p-0 mt-3">
                    <template #content>
                        <div v-if="validations.has('bulkOffer')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('bulkOffer')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="py-3">
                            <h6 class="text-base">Time Delay</h6>
                            <div class="flex justify-between w-full ">
                                <div class="flex items-center mb-2">
                                    <InputNumber v-model="delayRange[0]" :min="0" :max="delayRange[1] - 1"
                                        inputClass="!w-[30px] text-center" :disabled="taskStatus.bulkOffer.status" />
                                    <span>Sec</span>
                                </div>
                                <div class="flex items-center mb-2">
                                    <InputNumber v-model="delayRange[1]" :min="delayRange[0] + 1" :max="60"
                                        inputClass="!w-[30px] text-center" :disabled="taskStatus.bulkOffer.status" />
                                    <span>Sec</span>
                                </div>
                            </div>
                            <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles" v-model="delayRange"
                                range :step="1" ariaLabel="sec" :min="1" :max="60" class="mx-2"
                                :disabled="taskStatus.bulkOffer.status" />
                        </div>
                        <div class="flex items-center gap-x-3 mt-4">
                            <label for="bulkPercentDiscount" class=""> Percent Discount </label>
                            <InputNumber :min="0" :max="100" placeholder="0"
                                :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                                v-model="bulkPercentDiscount" inputId="bulkPercentDiscount" :useGrouping="true"
                                :disabled="taskStatus.bulkOffer.status" />
                            <span>%</span>
                        </div>
                        <div class="flex flex-col gap-y-4 mt-4 justify-center ">
                            <label for="bulkShippingOffer "> Shipping offer </label>
                            <div class="mx-auto">
                                <SelectButton :pt="shippingSelectLabelStyles" v-model="bulkShippingOffer"
                                    :options="shippingOfferOptions" option-label="label" option-value="value"
                                    aria-labelledby="basic" :disabled="taskStatus.bulkOffer.status" />
                            </div>
                        </div>
                        <div>
                            <Accordion class="mt-4" v-model:activeIndex="isBulkOfferExcludeByTypeEnabled">
                                <AccordionTab header="Exclude by Type">
                                    <div class="card flex justify-center">
                                        <div class="card flex flex-col flex-wrap gap-y-3">
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="bulkInventoryList"
                                                    v-model="bulkExcludeStatus.inventoryList" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'bulkInventoryList'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Exclude items using listing urls
                                                </label>
                                            </div>
                                            <div v-if="bulkExcludeStatus.inventoryList"
                                                class="card grid grid-cols-12 items-center">
                                                <Chips :pt="chipsListStyles" v-model="bulkExemptInventoryList"
                                                    separator="," input-class="w-full p-0 m-2"
                                                    class=" col-span-12 grid grid-cols-12 p-2 border border-[#0070b7] rounded-lg"
                                                    placeholder="Copy product URL and paste it here"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="bulkNewListings"
                                                    v-model="bulkExcludeStatus.newListings" onLabel="ON" offLabel="OFF"
                                                    class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'bulkNewListings'" class="hover:cursor-pointer col-span-5">
                                                    Exclude new with tags items
                                                </label>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="BulkBoutiqueListings"
                                                    v-model="bulkExcludeStatus.boutiqueListings" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'BulkBoutiqueListings'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Exclude boutique items
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            <Accordion class=" overflow-hidden" v-model:activeIndex="isBulkOfferExcludeByAgeEnabled">
                                <AccordionTab header="Exclude by Age">
                                    <div class="card flex justify-center">
                                        <div class="card flex flex-col flex-wrap  gap-y-3">
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="bulkItemsListedLately"
                                                    v-model="bulkExcludeStatus.itemsListedLately" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'bulkItemsListedLately'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Exclude items listed lately
                                                </label>
                                            </div>
                                            <div v-if="bulkExcludeStatus.itemsListedLately"
                                                class="w-full flex items-center gap-4">
                                                <label for="bulkListedLately" class="">Items Listed in the last </label>
                                                <InputNumber :min="1" placeholder="0"
                                                    :input-class="'w-[60px] text-sm p-3 border border-[#0070b7] rounded-lg '"
                                                    v-model="bulkListedLately" inputId="bulkListedLately"
                                                    :useGrouping="true" :disabled="taskStatus.bulkOffer.status" />
                                                <span class="text-sm">days</span>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="bulkOnlyOldItems"
                                                    v-model="bulkExcludeStatus.onlyOldItems" onLabel="ON" offLabel="OFF"
                                                    class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'bulkOnlyOldItems'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Only send offers for old items
                                                </label>
                                            </div>
                                            <div v-if="bulkExcludeStatus.onlyOldItems"
                                                class="w-full flex items-center gap-4">
                                                <label for="bulkListingsOlderThan" class="text-sm">Items Listed </label>
                                                <InputNumber :min="1" placeholder="0"
                                                    :input-class="'w-[60px] text-sm p-3 border border-[#0070b7] rounded-lg '"
                                                    v-model="bulkListingsOlderThan" inputId="bulkListingsOlderThan"
                                                    :useGrouping="true" :disabled="taskStatus.bulkOffer.status" />
                                                <span class="text-sm">days ago</span>
                                            </div>
                                            <div class="card grid grid-cols-6 items-center">
                                                <InputSwitch input-id="bulkPriceRangeForListings"
                                                    v-model="bulkExcludeStatus.priceRangeForListings" onLabel="ON"
                                                    offLabel="OFF" class="w-2rem me-2 col-span-1"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                                <label :for="'bulkPriceRangeForListings'"
                                                    class="hover:cursor-pointer col-span-5">
                                                    Only send offers on listings withing a price range
                                                </label>
                                            </div>
                                            <div v-if="bulkExcludeStatus.priceRangeForListings" class="w-full">
                                                <div class="flex justify-between w-full">
                                                    <InputNumber v-model="bulkPriceRange[0]" :min="minPriceRange"
                                                        :max="maxPriceRange"
                                                        :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg mb-4 text-center'"
                                                        :disabled="taskStatus.bulkOffer.status" />
                                                    <InputNumber v-model="bulkPriceRange[1]" :min="minPriceRange"
                                                        :max="maxPriceRange"
                                                        :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg mb-4 text-center'"
                                                        :disabled="taskStatus.bulkOffer.status" />
                                                </div>
                                                <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles"
                                                    v-model="bulkPriceRange" range :step="1" :min="minPriceRange"
                                                    :max="maxPriceRange" class="mb-10"
                                                    :disabled="taskStatus.bulkOffer.status" />
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            <div class="flex justify-end items-center mt-2">
                                <Button v-if="taskStatus.bulkOffer.status"
                                    @click="requireConfirmation('top', 'stopBulkOfferTask')"
                                    class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                                    Stop Current Task
                                </Button>
                                <Button v-else
                                    class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer text-sm hover:bg-white hover:text-[#0070b7] mt-2 mb-4"
                                    @click="validateBulkOfferRequest()">
                                    Start
                                    <div v-if="!authStore.hasActivePlan()"
                                        class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                        <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>
<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";
import { useTaskStatusStore } from '@/stores/taskStatus';
import { onBeforeMount, ref } from 'vue';
import { log, isValidUrl, scrollToTop } from '@/utils/index';
import { useActiveTab } from '@/stores/activeTab';
import { useAuthStore } from "../stores/auth";
import TabNavigation from "./TabNavigation.vue"
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Card from 'primevue/card';
import Chips from 'primevue/chips';
import ConfirmDialog from 'primevue/confirmdialog';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Slider from 'primevue/slider';
import Message from 'primevue/message';
import Button from "primevue/button";
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from "@/classes/LocalStorageWrapper";
import LoadingScreen from '@/components/LoadingScreen.vue';

const isLoading = ref(false);
const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore();
const tabView = useActiveTab();
const confirm = useConfirm();
const taskStatus = useTaskStatusStore();
const delayRange = ref<number[]>([3, 7]);
const sendOfferAfter = ref<number>(0)
const percentDiscount = ref<number>(0)
const bulkPercentDiscount = ref<number>(0)
const shippingOffer = ref<string>()
const bulkShippingOffer = ref<string>()
const isAutoOfferExcludeByTypeEnabled = ref<number | null>(0)
const isAutoOfferExcludeByAgeEnabled = ref<number | null>(0)
const isBulkOfferExcludeByTypeEnabled = ref<number | null>(0)
const isBulkOfferExcludeByAgeEnabled = ref<number | null>(0)
const excludeStatus = ref({
    inventoryList: false,
    newListings: false,
    boutiqueListings: false,
    itemsListedLately: false,
    onlyOldItems: false,
    priceRangeForListings: false
})
const bulkExcludeStatus = ref({
    inventoryList: false,
    newListings: false,
    boutiqueListings: false,
    itemsListedLately: false,
    onlyOldItems: false,
    priceRangeForListings: false
})
const autoOfferFunction = ref<boolean>(taskStatus.autoOffer.status);
const bulkOfferFunction = ref<boolean>(taskStatus.bulkOffer.status);
const exemptInventoryList = ref<string[]>([]);
const bulkExemptInventoryList = ref<string[]>([]);
const listedLately = ref<number>(0);
const bulkListedLately = ref<number>(0);
const listingsOlderThan = ref<number>(0);
const bulkListingsOlderThan = ref<number>(0);
const minPriceRange = ref<number>(1)
const maxPriceRange = ref<number>(10000)
const priceRange = ref<number[]>([minPriceRange.value, maxPriceRange.value]);
const bulkPriceRange = ref<number[]>([minPriceRange.value, maxPriceRange.value]);
const validations = ref(new Map())
const shippingOfferOptions = [
    {
        id: "5ff7647a5d29bbebfa25f9d0",
        label: 'No Discount',
        value: '$7.97',
    },
    {
        id: "5ff7647a5d29bbebfa25f9d1",
        label: '$5.95',
        value: '$5.95',
    },
    {
        id: "5ff7647a5d29bbebfa25f9d2",
        label: '$4.99',
        value: '$4.99',
    },
    {
        id: "5ff7647a5d29bbebfa25f9d3",
        label: 'Free',
        value: 'FREE',
    }
]



//PrimeVue Components Styles
const shippingSelectLabelStyles = {
    button: (params: any) => ({ class: params.context.active ? 'bg-[#0070b7] border-[#0070b7] focus:!border-none' : 'bg-gray-100 focus:!border-none' }),
    label: 'text-sm'
}

const chipsListStyles = {
    token: { class: 'col-span-12 grid grid-cols-12 mx-2  whitespace-pre-wrap break-all' },
    inputtoken: { class: 'col-span-12 grid grid-cols-12  mx-2' },
    input: { class: 'col-span-12' },
    container: { class: 'col-span-12 grid grid-cols-12 justify-center p-1' },
    label: { class: 'col-span-11 whitespace-pre-wrap' },
    removeTokenIcon: { class: 'col-span-1' }
}

const sliderStyles = {
    startHandler: { class: 'bg-[#0070b7]' },
    endHandler: { class: 'bg-[#0070b7]' },
    range: { class: 'bg-[#0070b7]' }
}

onBeforeMount(async () => {
    //load last used settings if found
    await loadAutoOfferSettings();
    await loadBulkOfferSettings();

    //collapse/expand Accordions
    isAutoOfferExcludeByTypeEnabled.value = excludeStatus.value.inventoryList ||
        excludeStatus.value.newListings ||
        excludeStatus.value.boutiqueListings ? 0 : null

    isAutoOfferExcludeByAgeEnabled.value = excludeStatus.value.itemsListedLately ||
        excludeStatus.value.onlyOldItems ||
        excludeStatus.value.priceRangeForListings ? 0 : null

    isBulkOfferExcludeByTypeEnabled.value = bulkExcludeStatus.value.inventoryList ||
        bulkExcludeStatus.value.newListings ||
        bulkExcludeStatus.value.boutiqueListings ? 0 : null

    isBulkOfferExcludeByAgeEnabled.value = bulkExcludeStatus.value.itemsListedLately ||
        bulkExcludeStatus.value.onlyOldItems ||
        bulkExcludeStatus.value.priceRangeForListings ? 0 : null

    hideUI.value = false

    setTimeout(() => {
        isLoading.value = false
    }, 300)
})

const updateSliderValue = (val: number[]) => {
    val[0] = Math.floor(val[0])
    val[1] = Math.floor(val[1])
}


const sendMessage = (msg: { message: string, type?: string, percentDiscount?: number, shippingOffer?: string, excludeStatus?: any, exemptInventoryList?: string[], listedLately?: number, listingsOlderThan?: number, priceRange?: number[], delay?: { min: number, max: number } }) => {
    window.parent.postMessage(msg, "*");
}

const onAutoOfferChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadAutoOfferSettings();
    }

    //reset error messages
    validations.value.set("autoOffer", []);
}

const onBulkOfferChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadBulkOfferSettings();
    }

    //reset error messages
    validations.value.set("bulkOffer", []);
}

const loadAutoOfferSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('autoOffersSettings')
        const autoOffersSettings = JSON.parse(storedData as string)

        if (!autoOffersSettings) return

        sendOfferAfter.value = autoOffersSettings.sendOfferAfter
        percentDiscount.value = autoOffersSettings.percentDiscount
        shippingOffer.value = autoOffersSettings.shippingOffer
        excludeStatus.value = autoOffersSettings.excludeStatus
        exemptInventoryList.value = autoOffersSettings.exemptInventoryList
        listedLately.value = autoOffersSettings.listedLately
        listingsOlderThan.value = autoOffersSettings.listingsOlderThan
        priceRange.value = autoOffersSettings.priceRange ?? [minPriceRange.value, maxPriceRange.value]
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const loadBulkOfferSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('bulkOffersSettings')
        const bulkOffersSettings = JSON.parse(storedData as string)

        if (!bulkOffersSettings) return

        bulkPercentDiscount.value = bulkOffersSettings.percentDiscount
        bulkShippingOffer.value = bulkOffersSettings.shippingOffer
        bulkExcludeStatus.value = bulkOffersSettings.excludeStatus
        bulkExemptInventoryList.value = bulkOffersSettings.exemptInventoryList
        bulkListedLately.value = bulkOffersSettings.listedLately
        bulkListingsOlderThan.value = bulkOffersSettings.listingsOlderThan
        bulkPriceRange.value = bulkOffersSettings.priceRange ?? [minPriceRange.value, maxPriceRange.value]
        delayRange.value = bulkOffersSettings.delayRange ?? [3, 7]
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const validateAutoOfferRequest = () => {
    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.autoOffer.status) return

    log("Validating Auto Offer");

    let messages = []
    if (sendOfferAfter.value <= 0) messages.push("Timer must be greater than 0");
    if (percentDiscount.value <= 0) messages.push("Discount must be greater than 0");
    if (percentDiscount.value > 100) messages.push("Discount cant be higher than 100%");
    if (shippingOffer.value == null) messages.push("Shipping Offer must be selected.");

    if (excludeStatus.value.itemsListedLately && listedLately.value <= 0) {
        messages.push("Excluded items days must be greater than 0 days.")
    }

    if (excludeStatus.value.onlyOldItems && listingsOlderThan.value <= 0) {
        messages.push("Items listed days must be greater than 0 days.")
    }

    if (excludeStatus.value.priceRangeForListings) {
        //price range checks
        if (!priceRange.value[0] || priceRange.value[0] < minPriceRange.value)
            messages.push(`Price range can't be less than ${minPriceRange.value}`)
        else if (!priceRange.value[1] || priceRange.value[1] < priceRange.value[0])
            messages.push("Invalid price range.");
        else if (!priceRange.value[1] || priceRange.value[1] > maxPriceRange.value)
            messages.push(`Price range can't be more than ${maxPriceRange.value}`);
    }

    if (excludeStatus.value.inventoryList && !exemptInventoryList.value.length) {
        messages.push("Excluded products list is empty.")
    }
    else if (excludeStatus.value.inventoryList) {
        const invalidUrls = exemptInventoryList.value.filter(item => !isValidUrl(item))
        if (invalidUrls.length) {
            messages.push(`Invalid urls: ${invalidUrls.join(', ')}`)
        }
    }

    validations.value.set("autoOffer", messages);

    if (messages.length) {
        scrollToTop()
        return
    }

    //Trim and remove duplicate urls from exemptInventoryList
    exemptInventoryList.value = [...new Set<string>(exemptInventoryList.value.map((item: string) => item.trim()))]

    //update and send to extension to start task
    const taskData = { message: 'autoOffers', sendOfferAfter: sendOfferAfter.value, percentDiscount: percentDiscount.value, shippingOffer: shippingOffer.value, excludeStatus: JSON.parse(JSON.stringify(excludeStatus.value)), exemptInventoryList: JSON.parse(JSON.stringify(exemptInventoryList.value)), listedLately: listedLately.value, listingsOlderThan: listingsOlderThan.value, priceRange: JSON.parse(JSON.stringify(priceRange.value)) }

    //start message listener
    window.addEventListener("message", autoOfferMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeAutoOfferStatus(true);

    //store latest settings in local storage
    localStorageWrapper.set('autoOffersSettings', JSON.stringify(taskData))
}

const validateBulkOfferRequest = () => {
    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.bulkOffer.status) return

    log("Validating Bulk Offer");

    let messages = []

    if (!delayRange.value[0] || delayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!delayRange.value[1] || delayRange.value[1] < delayRange.value[0])
        messages.push("Invalid delay range.");

    if (bulkPercentDiscount.value <= 0) messages.push("Discount must be greater than 0");
    if (bulkPercentDiscount.value > 100) messages.push("Discount cant be higher than 100%");
    if (bulkShippingOffer.value == null) messages.push("Shipping Offer must be selected.");

    if (bulkExcludeStatus.value.itemsListedLately && bulkListedLately.value <= 0) {
        messages.push("Excluded items days must be greater than 0 days.")
    }

    if (bulkExcludeStatus.value.onlyOldItems && bulkListingsOlderThan.value <= 0) {
        messages.push("Items listed days must be greater than 0 days.")
    }

    if (bulkExcludeStatus.value.priceRangeForListings) {
        //price range checks
        if (!bulkPriceRange.value[0] || bulkPriceRange.value[0] < minPriceRange.value)
            messages.push(`Price range can't be less than ${minPriceRange.value}`)
        else if (!bulkPriceRange.value[1] || bulkPriceRange.value[1] < bulkPriceRange.value[0])
            messages.push("Invalid price range.");
        else if (!bulkPriceRange.value[1] || bulkPriceRange.value[1] > maxPriceRange.value)
            messages.push(`Price range can't be more than ${maxPriceRange.value}`);
    }

    if (bulkExcludeStatus.value.inventoryList && !bulkExemptInventoryList.value.length) {
        messages.push("Excluded products list is empty.")
    }
    else if (bulkExcludeStatus.value.inventoryList) {
        const invalidUrls = bulkExemptInventoryList.value.filter(item => !isValidUrl(item))
        if (invalidUrls.length) {
            messages.push(`Invalid urls: ${invalidUrls.join(', ')}`)
        }
    }


    validations.value.set("bulkOffer", messages);

    if (messages.length) {
        scrollToTop()
        return
    }

    //Trim and remove duplicate urls from bulkExemptInventoryList
    bulkExemptInventoryList.value = [...new Set(bulkExemptInventoryList.value.map(item => item.trim()))]

    //update and send to extension to start task
    const taskData = { message: 'bulkOffers', percentDiscount: bulkPercentDiscount.value, shippingOffer: bulkShippingOffer.value, excludeStatus: JSON.parse(JSON.stringify(bulkExcludeStatus.value)), exemptInventoryList: JSON.parse(JSON.stringify(bulkExemptInventoryList.value)), listedLately: bulkListedLately.value, listingsOlderThan: bulkListingsOlderThan.value, priceRange: JSON.parse(JSON.stringify(bulkPriceRange.value)), delay: { min: delayRange.value[0], max: delayRange.value[1] } }

    //start message listener
    window.addEventListener("message", bulkOfferMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeBulkOfferStatus(true);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: delayRange.value,
    }

    //store latest settings in local storage
    localStorageWrapper.set('bulkOffersSettings', JSON.stringify(config))
}

const requireConfirmation = (position: any, message: string) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    });
};

//This will handle messages sent from the extension to the web server
const autoOfferMessagesHandler = (event: any) => {
    if (event.data.message == "autoOfferTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeAutoOfferStatus(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", autoOfferMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartAutoOfferTask") {

        //validations.value.set("autoOffer", [event.data.error]);
        taskStatus.changeAutoOfferStatus(false);
    }
}

//This will handle messages sent from the extension to the web server
const bulkOfferMessagesHandler = (event: any) => {
    if (event.data.message == "bulkOfferTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeBulkOfferStatus(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", bulkOfferMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartBulkOfferTask") {

        ///validations.value.set("bulkOffer", [event.data.error]);
        taskStatus.changeBulkOfferStatus(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", bulkOfferMessagesHandler);
    }
}

</script>

<style lang="scss">
:deep(.p-accordion) {
    .p-accordion-header-link {
        padding: 10px 5px !important;
    }

    .p-accordion-content {
        padding: 10px 0;
    }
}

.p-chips {
    .p-chips-multiple-container {
        height: fit-content !important;
    }

    .p-chips-token {
        margin: 5px;
    }
}
</style>