<template>
    <Card class="!p-0" :class="activeBGStyles">
        <template #title>
            <div class="flex items-center justify-between">
                <h6 class="text-base">Activity Returner <InfoTip
                        tip="Return community activities (i.e. shares and follows) automatically."></InfoTip>
                </h6>
                <div class="flex items-center">
                    <InputSwitch input-id="activityReturnerFunction" v-model="activityReturnerFunction" onLabel="ON"
                        offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.activityReturner.status"
                        @change="onActivityReturnerChange" />
                </div>
            </div>
        </template>
        <template #content v-if="activityReturnerFunction">

            <Card class="mt-4 p-0">
                <template #title>
                    <h6 class="text-base">Set Return Activity Type</h6>
                </template>
                <template #content>
                    <div v-if="validations.has('activities')">
                        <Message severity="error" v-for="(errorMessage, index) of validations.get('activities')"
                            :keys="errorMessage" icon="null" :closable="false">
                            {{ errorMessage }}
                        </Message>
                    </div>
                    <div class="mt-4">
                        <div class="flex items-center mt-2">
                            <InputSwitch input-id="returnSharesActive" v-model="returnSharesActive" onLabel="ON"
                                offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.activityReturner.status" />
                            <label :for="'returnSharesActive'" class="hover:cursor-pointer">
                                Shares
                            </label>
                        </div>
                        <div class="flex items-center mt-2">
                            <InputSwitch input-id="returnFollowsActive" v-model="returnFollowsActive" onLabel="ON"
                                offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.activityReturner.status" />
                            <label :for="'returnFollowsActive'" class="hover:cursor-pointer">
                                Follows
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col mt-4">
                        <div class="flex items-center">
                            <div>
                                <label for="maxSharesPerDay" class="ml-2 hover:cursor-pointer">
                                    Max activity returner daily shares
                                </label>
                                <InputNumber :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '"
                                    v-model="maxSharesPerDay" inputId="maxSharesPerDay" :min="1" :useGrouping="true"
                                    :disabled="taskStatus.activityReturner.status" />
                                <InfoTip class="ml-2"
                                    tip="The total number of shares (followers, community, and activity returner) should not exceed 8,000 per day. While each setting is independent, the combined total must stay within this limit.">
                                </InfoTip>

                            </div>
                        </div>
                    </div>
                </template>
            </Card>

            <Card class="mt-4 p-0">
                <template #title>
                    <h6 class="text-base">Activity Return Quantity</h6>
                </template>
                <template #content>
                    <div v-if="validations.has('itemsToShare')">
                        <Message severity="error" v-for="(errorMessage, index) of validations.get('itemsToShare')"
                            :keys="errorMessage" icon="null" :closable="false">
                            {{ errorMessage }}
                        </Message>
                    </div>

                    <div class="flex flex-col gap-3 mt-4">
                        <div class="flex items-center">
                            <RadioButton v-model="activityReturnerLimitMode" inputId="dynamicLimit" name="limitCheck"
                                :disabled="taskStatus.activityReturner.status" value="dynamic" />
                            <label for="dynamicLimit" class="ml-2 hover:cursor-pointer">Same as received <InfoTip
                                    tip="Return the same number of shares/follows you received."></InfoTip>
                            </label>
                        </div>
                        <div class="flex items-center" @click="activityReturnerLimitMode = 'fixed'">
                            <RadioButton v-model="activityReturnerLimitMode" inputId="fixedLimit" name="limitCheck"
                                value="fixed" :disabled="taskStatus.activityReturner.status" />
                            <div>
                                <label for="fixedLimit" class="m-2 hover:cursor-pointer">
                                    Fixed number
                                </label>
                                <InputNumber placeholder="0"
                                    :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '"
                                    v-model="fixedLimit" inputId="limit" :useGrouping="true"
                                    :disabled="taskStatus.activityReturner.status" />

                            </div>
                        </div>
                        <div class="flex items-center" @click="activityReturnerLimitMode = 'max'">
                            <RadioButton v-model="activityReturnerLimitMode" inputId="maxLimit" name="limitCheck"
                                value="max" :disabled="taskStatus.activityReturner.status" />
                            <div>
                                <label for="maxLimit" class="m-2 hover:cursor-pointer">
                                    Same as received up to
                                </label>
                                <InputNumber placeholder="0"
                                    :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '" v-model="maxLimit"
                                    inputId="limit" :useGrouping="true"
                                    :disabled="taskStatus.activityReturner.status" />
                                <InfoTip class="ml-2"
                                    tip="Return the same number of shares/follows you received up to a maximum.">
                                </InfoTip>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>

            <div class="flex justify-end mt-5 gap-x-4  p-1">
                <Button v-if="taskStatus.activityReturner.status" @click="requireConfirmation('top')"
                    class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                    Stop Current Task
                </Button>
                <Button v-else
                    class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                    @click="validateActivityReturnerRequest()">
                    Start
                </Button>
            </div>


        </template>
    </Card>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { format } from "date-fns";
import { useAuthStore } from "../stores/auth";
import Chips from 'primevue/chips';
import Slider from 'primevue/slider';
import Message from 'primevue/message';
import * as Sentry from '@sentry/vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';
import Tag from "primevue/tag";
import RadioButton from "primevue/radiobutton";
import ConfirmDialog from "primevue/confirmdialog";
import type ScheduledTask from "@/types/ScheduledTask"
import SharingType from "@/enums/SharingType"
import { useTaskStatusStore } from "@/stores/taskStatus";
import { log, scrollToTop } from '@/utils/index';
import LocalStorageWrapper from "@/classes/LocalStorageWrapper";
import InfoTip from '@/components/InfoTip.vue';
import { useConfirm } from 'primevue/useconfirm';
import { useActiveTab } from '@/stores/activeTab';
import { isValidClosetUrl } from '@/utils/index';


const localStorageWrapper = LocalStorageWrapper.getInstance()
const taskStatus = useTaskStatusStore();
const activityReturnerLimitMode = ref('dynamic')
const fixedLimit = ref<number>(0)
const maxLimit = ref<number>(0)
const returnSharesActive = ref<boolean>(true)
const returnFollowsActive = ref<boolean>(true)
const validations = ref(new Map())
const maxSharesPerDay = ref<number>(1000)
const activityReturnerLimitModes = ['dynamic', 'fixed', 'max']
const scheduledTasks = ref<ScheduledTask[]>([]);
const authStore = useAuthStore();
const delayRange = ref<number[]>([3, 7]);
const confirm = useConfirm();
const tabView = useActiveTab();
const activityReturnerFunction = ref<boolean>(taskStatus.activityReturner.status);
const sharingClosetsList = ref<string[]>([]);
const activeBGStyles = { 'bg-gray-100': activityReturnerFunction }


onMounted(async () => {
    //load last used settings if found
    await loadActivityReturnerSettings();
})



const sendMessage = (msg: any) => {
    window.parent.postMessage(msg, "*");
}

const onActivityReturnerChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadActivityReturnerSettings();
    }

    //reset error messages
    validations.value.set("activityReturner", []);
}

const loadActivityReturnerSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('activityReturnerSettings')
        const activityReturnerSettings = JSON.parse(storedData as string)

        if (!activityReturnerSettings) return

        activityReturnerLimitMode.value = activityReturnerSettings.activityReturnerLimitMode
        returnFollowsActive.value = activityReturnerSettings.returnFollowsActive ?? true
        returnSharesActive.value = activityReturnerSettings.returnSharesActive ?? true
        maxSharesPerDay.value = activityReturnerSettings.maxSharesPerDay ?? 0
        fixedLimit.value = activityReturnerSettings.fixedLimit ?? 0
        maxLimit.value = activityReturnerSettings.maxLimit ?? 0

    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const validateActivityReturnerRequest = async () => {
    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.activityReturner.status) return

    log("Validating Activity Returner");

    //reset error messages
    validations.value = new Map()

    let errorFound = false
    let messages = []

    if (!returnFollowsActive.value && !returnSharesActive.value) {
        messages.push("Choose at least one activity.")
    }

    if (!maxSharesPerDay.value || maxSharesPerDay.value <= 0) {
        messages.push("Maximum number of items to share must be greater than zero.")
    }

    validations.value.set("activities", messages);

    if (messages.length)
        errorFound = true

    messages = []

    if (!activityReturnerLimitMode.value || activityReturnerLimitModes.some(mode => activityReturnerLimitMode.value == mode)) {
        messages.push("Please select a valid option.")
    } else if (activityReturnerLimitMode.value === "fixed" && fixedLimit.value <= 0) {
        messages.push("Select a valid limit higher than 0.")
    } else if (activityReturnerLimitMode.value === "max" && maxLimit.value <= 0) {
        messages.push("Select a valid limit higher than 0.")
    }

    if (errorFound) {
        scrollToTop()
        return
    }

    startActivityReturner()

}

const startActivityReturner = () => {

    const taskData = { message: 'handleActivityReturner', activityReturnerLimitMode: activityReturnerLimitMode.value, returnFollowsActive: returnFollowsActive.value, returnSharesActive: returnSharesActive.value, maxSharesPerDay: maxSharesPerDay.value, fixedLimit: fixedLimit.value, maxLimit: maxLimit.value }

    //start message listener
    window.addEventListener("message", messagesHandler);

    sendMessage(taskData);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
    }


    taskStatus.changeActivityReturner(true)

    //store latest settings in local storage
    localStorageWrapper.set('activityReturnerSettings', JSON.stringify(config))
};

const messagesHandler = async (event: any) => {
    if (event.data.message == "activityReturnerTaskEnded") {

        //the stop request was send by the user
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeActivityReturner(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", messagesHandler);
            return
        }

    }
    else if (event.data.message == "failedToStartActivityReturnerTask") {


        taskStatus.changeActivityReturner(false);

        //stop message listener until the task is started again
        window.removeEventListener("message", messagesHandler);
    }
}

const requireConfirmation = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message: 'stopActivityReturnerTask' });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass:
            'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass:
            'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    })
}

</script>

<style lang="scss" scoped>
:deep(.p-radiobutton) {
    .p-radiobutton-box {
        border: 1px solid #0070b7;
        border-radius: 50%;
    }
}
</style>
