<template>
    <div class="feature-container flex items-center" ref="feature">
        <div v-if="showSparkle" class="new-badge mr-2" ref="newBadge">
            NEW
            <div class="sparkle sparkle-1"></div>
            <div class="sparkle sparkle-2"></div>
            <div class="sparkle sparkle-3"></div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
    featureId: {
        type: String,
        required: true
    }
});

const showSparkle = ref(false);
const sparkle = ref(null);

onMounted(() => {
    // Check if the sparkle has been shown before for this featureId
    const seenFeature = localStorage.getItem(`feature-${props.featureId}`);

    if (!seenFeature) {
        showSparkle.value = true;
        // Save that the user has seen this feature sparkle
        localStorage.setItem(`feature-${props.featureId}`, 'seen');
    }
});
</script>

<style scoped>
.feature-container {
    position: relative;
}

/* NEW badge styling */
.new-badge {
    /* position: absolute; */
    height: 26px;
    right: 105px;
    top: 25px;
    background-color: #ff4081;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pop-in 0.5s ease-out;
    z-index: 1000;
}

/* Sparkle styling */
.sparkle {
    position: absolute;
    right: 130px;
    top: 35px;
    width: 6px;
    height: 6px;
    background-color: gold;
    border-radius: 50%;
    box-shadow: 0 0 8px gold, 0 0 12px yellow;
    opacity: 0;
    animation: sparkle-animation 1.5s infinite;
}

/* Specific positions for sparkles */
.sparkle-1 {
    top: -5px;
    left: -5px;
    animation-delay: 0s;
}

.sparkle-2 {
    top: -5px;
    /* right: -5px; */
    left: 40px;
    animation-delay: 0.3s;
}

.sparkle-3 {
    bottom: -5px;
    left: 5px;
    animation-delay: 0.6s;
}

/* Animation for sparkles */
@keyframes sparkle-animation {
    0% {
        transform: scale(0.8);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Animation for the "NEW" badge popping in */
@keyframes pop-in {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
