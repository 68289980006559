<template>
    <TabNavigation :is-active="taskStatus.communityShare.status || taskStatus.activityReturner.status" />
    <LoadingScreen :isLoading="isLoading" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">

        <Card class="!p-0" :class="activeBGStyles">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Community Share</h6>
                    <div class="flex items-center">
                        <InputSwitch input-id="communityShareFunction" v-model="communityShareFunction" onLabel="ON"
                            offLabel="OFF" class="w-2rem me-2" :disabled="taskStatus.communityShare.status"
                            @change="onCommunityShareChange" />
                    </div>
                </div>
            </template>
            <template #content v-if="communityShareFunction">

                <Card class="p-0 mt-3">
                    <template #title>
                        <h6 class="text-base">Sharing Closets</h6>
                    </template>
                    <template #content>
                        <div v-if="validations.has('sharingCloset')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('sharingCloset')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>

                        <div class="mt-4">
                            <Chips :pt="chipsListStyles" v-model="sharingClosetsList" separator=","
                                input-class="w-full "
                                class=" col-span-12 grid grid-cols-12 p-1 border border-[#0070b7] rounded-lg"
                                placeholder="Copy closet/brand URLs and paste it here"
                                :disabled="taskStatus.communityShare.status" />
                        </div>

                    </template>
                </Card>
                <Card class="mt-4 p-0">
                    <template #title>
                        <h6 class="text-base">Sharing Mode</h6>
                    </template>
                    <template #content>
                        <div v-if="validations.has('sharingMode')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('sharingMode')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="mt-4">
                            <SelectButton :pt="selectLabelStyles" v-model="sharingType" @change="changeSharingMode"
                                :options="sharingOptions" option-label="label" option-value="value"
                                aria-labelledby="basic" :disabled="taskStatus.communityShare.status" />
                        </div>
                        <div v-if="sharingType == 'continuous'" class="mt-4">
                            <h6 class="text-md font-bold">Continuous Cycles</h6>
                            <div class="flex mt-2">
                                <div class="flex items-center gap-x-3">
                                    <span>Share</span>
                                    <InputNumber :min="1" placeholder="0"
                                        :input-class="'w-[100px] p-3 border border-[#0070b7] rounded-lg '"
                                        v-model="shareCycles" inputId="shareCycles" :useGrouping="true"
                                        :disabled="taskStatus.communityShare.status" />
                                    <span>times</span>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="flex flex-wrap items-center gap-x-3">
                                    <span>Wait
                                        <InputNumber :min="1" placeholder="0"
                                            :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                                            v-model="cyclesDelay.min" inputId="cyclesDelayMin" :useGrouping="true"
                                            :disabled="taskStatus.communityShare.status" />
                                        to
                                        <InputNumber :min="cyclesDelay.min + 1" placeholder="0"
                                            :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg'"
                                            v-model="cyclesDelay.max" inputId="cyclesDelayMax" :useGrouping="true"
                                            :disabled="taskStatus.communityShare.status" />
                                        min between cycles
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="sharingType == 'scheduled'" class="mt-4">
                            <div class="flex flex-col mt-2">
                                <div class="flex items-center gap-x-3">
                                    <Calendar class="w-[100px] p-3 border border-[#0070b7] rounded-lg"
                                        id="calendar-timeOnly" v-model="scheduledTaskTime" timeOnly hourFormat="12"
                                        :disabled="taskStatus.communityShare.status" />
                                    <div class="flex items-center">
                                        <InputSwitch input-id="repeatDaily" v-model="repeatDaily" onLabel="ON"
                                            offLabel="OFF" class="w-2rem me-2"
                                            :disabled="taskStatus.communityShare.status" />
                                        <label :for="'repeatDaily'" class="hover:cursor-pointer">
                                            Repeat Daily
                                        </label>
                                    </div>
                                    <Button
                                        class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                                        @click="addToScheduledTasks()" :disabled="taskStatus.communityShare.status">
                                        Add
                                    </Button>
                                </div>
                            </div>
                            <div class="flex flex-col gap-3 mt-4 p-2">
                                <div class="flex items-center justify-between mt-2"
                                    v-for="(item, index) in scheduledTasks" :key="index">
                                    <div class="flex gap-x-2 flex-1">
                                        <p>{{ format(item.time, 'hh:mm aaa') }}</p>
                                        <Tag v-if="item.repeat" severity="warning" value="Repeat Daily" rounded>
                                        </Tag>
                                    </div>
                                    <Tag v-if="taskStatus.communityShare.status && item.repeat && item.processed"
                                        severity="success" :value="'Completed: ' + String(item.processed)" rounded>
                                    </Tag>
                                    <Tag v-if="taskStatus.communityShare.status && !item.repeat && item.processed"
                                        severity="success" value="Complete" rounded></Tag>
                                    <Tag v-if="taskStatus.communityShare.status && !item.processed" severity="info"
                                        value="Scheduled" rounded></Tag>
                                    <Button v-if="!taskStatus.communityShare.status"
                                        class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                                        @click="removeScheduledTask(index, item.time)">
                                        Remove
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
                <Card class="mt-4 p-0">
                    <template #title>
                        <h6 class="text-base">Time Delay</h6>
                    </template>
                    <template #content>
                        <div v-if="validations.has('timeDelay')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('timeDelay')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="flex justify-between w-full">
                            <div class="flex items-center mb-2">
                                <InputNumber v-model="delayRange[0]" :min="0" :max="delayRange[1] - 1"
                                    inputClass="!w-[30px] text-center" :disabled="taskStatus.communityShare.status" />
                                <span>Sec</span>
                            </div>
                            <div class="flex items-center mb-2">
                                <InputNumber v-model="delayRange[1]" :min="delayRange[0] + 1" :max="60"
                                    inputClass="!w-[30px] text-center" :disabled="taskStatus.communityShare.status" />
                                <span>Sec</span>
                            </div>
                        </div>
                        <Slider v-on:update:model-value="updateSliderValue" :pt="sliderStyles" v-model="delayRange"
                            range :step="1" ariaLabel="sec" :min="1" :max="60" class=""
                            :disabled="taskStatus.communityShare.status" />
                    </template>
                </Card>
                <Card class="mt-4 p-0">
                    <template #title>
                        <h6 class="text-base">Number of items to share</h6>
                    </template>
                    <template #content>
                        <div v-if="validations.has('itemsToShare')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('itemsToShare')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="flex flex-col mt-4">
                            <div class="flex items-center">
                                <div>
                                    <label for="someProducts" class="ml-2 hover:cursor-pointer">
                                        Max number of community shares daily
                                    </label>
                                    <InputNumber :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '"
                                        v-model="maxSharesPerDay" inputId="maxSharesPerDay" :min="1" :useGrouping="true"
                                        :disabled="taskStatus.communityShare.status" />
                                    <InfoTip class="ml-2"
                                        tip="The total number of shares (followers, community, and activity returner) should not exceed 8,000 per day. While each setting is independent, the combined total must stay within this limit.">
                                    </InfoTip>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col gap-3 mt-4">
                            <div class="flex items-center" @click="limit = 0">
                                <RadioButton v-model="allProducts" inputId="allProducts" name="limitCheck" :value="true"
                                    :disabled="taskStatus.communityShare.status" />
                                <label for="allProducts" class="ml-2 hover:cursor-pointer">Share all available
                                    items</label>
                            </div>
                            <div class="flex items-center">
                                <RadioButton v-model="allProducts" inputId="someProducts" name="limitCheck"
                                    :value="false" :disabled="taskStatus.communityShare.status" />
                                <div>
                                    <label for="someProducts" class="ml-2 hover:cursor-pointer">
                                        Share set number of items
                                    </label>
                                    <InputNumber placeholder="0"
                                        :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg '"
                                        v-model="limit" inputId="limit" :useGrouping="true" @click="allProducts = false"
                                        :disabled="taskStatus.communityShare.status" />
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
                <Card class="mt-4 p-0">
                    <template #title>
                        <h6 class="text-base">Sharing Order</h6>
                    </template>
                    <template #content>
                        <div v-if="validations.has('sharingOrder')">
                            <Message severity="error" v-for="(errorMessage, index) of validations.get('sharingOrder')"
                                :keys="errorMessage" icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="mt-4">
                            <SelectButton :pt="selectLabelStyles" v-model="closetOrder" :options="closetOrderOptions"
                                option-label="label" option-value="value" aria-labelledby="basic"
                                :disabled="taskStatus.communityShare.status" />
                        </div>
                        <div class="mt-4">
                            <h6 class="text-md font-bold">Additional Settings</h6>

                            <div class="flex items-center mt-2">
                                <InputSwitch input-id="shareToParty" v-model="shareToParty" onLabel="ON" offLabel="OFF"
                                    class="w-2rem me-2" :disabled="taskStatus.communityShare.status" />
                                <label :for="'shareToParty'" class="hover:cursor-pointer">
                                    Share eligible items to party
                                </label>
                            </div>
                        </div>
                    </template>
                </Card>

                <div class="flex justify-end mt-5 gap-x-4  p-1">
                    <Button v-if="taskStatus.communityShare.status" @click="requireConfirmation('top')"
                        class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                        Stop Current Task
                    </Button>
                    <Button v-else
                        class="border border-[#0070b7] font-bold bg-[#0070b7] text-white px-8 py-1 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                        @click="validateCommunityShareRequest()">
                        Start
                    </Button>
                </div>


            </template>
        </Card>

        <ActivityReturnerTab class="mt-4"></ActivityReturnerTab>

        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue';
import { format } from "date-fns";
import { useAuthStore } from "../stores/auth";
import Chips from 'primevue/chips';
import Slider from 'primevue/slider';
import Message from 'primevue/message';
import * as Sentry from '@sentry/vue';
import TabNavigation from "./TabNavigation.vue"
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';
import Tag from "primevue/tag";
import RadioButton from "primevue/radiobutton";
import ConfirmDialog from "primevue/confirmdialog";
import type ScheduledTask from "@/types/ScheduledTask"
import SharingType from "@/enums/SharingType"
import { useTaskStatusStore } from "@/stores/taskStatus";
import { log, scrollToTop } from '@/utils/index';
import LocalStorageWrapper from "@/classes/LocalStorageWrapper";
import LoadingScreen from '@/components/LoadingScreen.vue';
import { useConfirm } from 'primevue/useconfirm';
import { useActiveTab } from '@/stores/activeTab';
import { isValidClosetUrl } from '@/utils/index';
import ActivityReturnerTab from '@/components/ActivityReturnerTab.vue';
import InfoTip from './InfoTip.vue';

const isLoading = ref(false);
const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const taskStatus = useTaskStatusStore();
const allProducts = ref<boolean>(true)
const shareToParty = ref<boolean>(false)
const shareCycles = ref<number>(0)
const maxSharesPerDay = ref<number>(1000)
const cyclesDelay = ref<{ min: number, max: number }>({ min: 0, max: 0 })
const closetOrder = ref<string>("topToBottom")
const limit = ref<number>(0)
const sharingType = ref<string>("oneTime");
const scheduledTaskTime = ref<Date>(new Date());
const repeatDaily = ref<boolean>(false);
const scheduledTasks = ref<ScheduledTask[]>([]);
const uniqueScheduledTasksSet = new Set<string>()
const authStore = useAuthStore();
const delayRange = ref<number[]>([3, 7]);
const sendOfferAfter = ref<number>(0)
const percentDiscount = ref<number>(0)
const shippingOffer = ref<string>()
const confirm = useConfirm();
const tabView = useActiveTab();

const excludeStatus = ref({
    inventoryList: false,
    newListings: false,
    boutiqueListings: false,
    itemsListedLately: false,
    onlyOldItems: false,
    priceRangeForListings: false
})
const bulkExcludeStatus = ref({
    inventoryList: false,
    newListings: false,
    boutiqueListings: false,
    itemsListedLately: false,
    onlyOldItems: false,
    priceRangeForListings: false
})
const communityShareFunction = ref<boolean>(taskStatus.communityShare.status);
const activityReturnerFunction = ref<boolean>(taskStatus.activityReturner.status);
const sharingClosetsList = ref<string[]>([]);
const listedLately = ref<number>(0);
const bulkListedLately = ref<number>(0);
const listingsOlderThan = ref<number>(0);
const bulkListingsOlderThan = ref<number>(0);
const minPriceRange = ref<number>(1)
const maxPriceRange = ref<number>(10000)
const priceRange = ref<number[]>([minPriceRange.value, maxPriceRange.value]);
const bulkPriceRange = ref<number[]>([minPriceRange.value, maxPriceRange.value]);
const validations = ref(new Map())
const delay = ref<{
    min: number, max: number
}>({ min: 1, max: 50 });


const closetOrderOptions = [
    {
        label: "Top To Bottom",
        value: "topToBottom"
    },
    {
        label: "Keep Order",
        value: "keepOrder"
    },
    {
        label: "Randomize",
        value: "random"
    }
]

//TODO: replace string values with SharingType enum values
const sharingOptions = [
    {
        label: 'One Time',
        value: "oneTime",
    },
    {
        label: 'Continuous',
        value: "continuous",
    },
    {
        label: 'Scheduled',
        value: "scheduled",
    }
];

//PrimeVue Components Styles
const selectLabelStyles = {
    button: (params: any) => ({
        class: params.context.active ? 'bg-[#0070b7] border-[#0070b7] focus:!border-none !p-2' : 'bg-gray-100 focus:!border-none focus:!shadow-none !p-2'
    }),
    label: 'text-sm'
}

const sliderStyles = {
    startHandler: { class: 'bg-[#0070b7]' },
    endHandler: { class: 'bg-[#0070b7]' },
    range: { class: 'bg-[#0070b7]' }
}

const updateSliderValue = (val: number[]) => {
    val[0] = Math.floor(val[0])
    val[1] = Math.floor(val[1])
}

const chipsListStyles = {
    token: { class: 'col-span-12 grid grid-cols-12 mx-2  whitespace-pre-wrap break-all' },
    inputtoken: { class: 'col-span-12 grid grid-cols-12  mx-2' },
    input: { class: 'col-span-12' },
    container: { class: 'col-span-12 grid grid-cols-12 justify-center p-1' },
    label: { class: 'col-span-11 whitespace-pre-wrap' },
    removeTokenIcon: { class: 'col-span-1' }
}

const activeBGStyles = { 'bg-gray-100': communityShareFunction }


onMounted(async () => {
    //load last used settings if found
    await loadCommunityShareSettings();

    hideUI.value = false

    setTimeout(() => {
        isLoading.value = false
    }, 300)
})



const sendMessage = (msg: { message: string, type?: string, percentDiscount?: number, shippingOffer?: string, excludeStatus?: any, sharingClosetsList?: string[], listedLately?: number, listingsOlderThan?: number, priceRange?: number[], delay?: { min: number, max: number } }) => {
    window.parent.postMessage(msg, "*");
}

const onCommunityShareChange = (event: Event) => {

    const target = event.target as HTMLInputElement;

    if (target?.checked) {
        //load last used settings if found
        loadCommunityShareSettings();
    }

    //reset error messages
    validations.value.set("communityShare", []);
}

const loadCommunityShareSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('communityShareSettings')
        const communityShareSettings = JSON.parse(storedData as string)

        if (!communityShareSettings) return

        maxSharesPerDay.value = communityShareSettings.maxSharesPerDay ?? 8000
        limit.value = communityShareSettings.limit
        closetOrder.value = communityShareSettings.closetOrder
        delay.value = communityShareSettings.delay
        delayRange.value = communityShareSettings.delayRange
        sharingType.value = communityShareSettings.sharingType
        cyclesDelay.value = communityShareSettings.cyclesDelay
        shareToParty.value = communityShareSettings.shareToParty
        shareCycles.value = communityShareSettings.shareCycles ?? 0
        scheduledTasks.value = communityShareSettings.scheduledTasks
        allProducts.value = communityShareSettings.allProducts
        sharingClosetsList.value = communityShareSettings.sharingClosetsList ?? []

        for (const scheduledTask of scheduledTasks.value) {
            uniqueScheduledTasksSet.add(format(scheduledTask.time, "HH:mm"))
        }
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}

const validateCommunityShareRequest = async () => {
    if (!authStore.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.communityShare.status) return

    log("Validating Community Share");

    //reset error messages
    validations.value = new Map()

    let errorFound = false
    let messages = []

    if (!sharingClosetsList.value.length) {
        messages.push("Closets list is empty.")
    } else {
        const invalidUrls = sharingClosetsList.value.filter(item => !isValidClosetUrl(item))
        if (invalidUrls.length) {
            messages.push(`Invalid urls: ${invalidUrls.join(', ')}`)
        }
    }

    validations.value.set("sharingCloset", messages);

    if (messages.length)
        errorFound = true

    messages = []

    //validating sharing mode
    if (!sharingOptions.some((option) => option.value === sharingType.value)) {
        messages.push("Please select a valid option.");
    }

    if (sharingType.value === "continuous") {
        if (shareCycles.value <= 0) {
            messages.push("Share cycles must be greater than zero.");
        }

        if (!cyclesDelay.value.min || !cyclesDelay.value.max) {
            messages.push("Waiting time should be at least 1 minute.");
        }
        else if (cyclesDelay.value.min >= cyclesDelay.value.max) {
            messages.push("Invalid waiting time cycles.");
        }
    }

    if (sharingType.value === "scheduled") {
        if (scheduledTasks.value.length === 0) {
            messages.push("No tasks scheduled.");
        }
    }

    validations.value.set("sharingMode", messages);

    if (messages.length)
        errorFound = true

    // validating time delay
    messages = []

    if (delayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (delayRange.value[1] < delayRange.value[0])
        messages.push("Invalid delay range.");

    validations.value.set("timeDelay", messages);

    if (messages.length)
        errorFound = true

    // validating share items
    messages = []

    if (!maxSharesPerDay.value || maxSharesPerDay.value <= 0) {
        messages.push("Maximum number of items to share must be greater than zero.")
    }
    else if (maxSharesPerDay.value > 8000)
        messages.push("Maximum number of items to share can't be greater than 8000")

    if (!allProducts.value && limit.value <= 0)
        messages.push("Set number of items to share.")

    validations.value.set("itemsToShare", messages);

    if (messages.length)
        errorFound = true

    // validating sharing order
    messages = []

    if (!closetOrderOptions.some((option) => option.value === closetOrder.value))
        messages.push("Please select a valid sharing order.");

    if (messages.length) {
        errorFound = true
        validations.value.set("sharingOrder", messages);
    }

    if (errorFound) {
        scrollToTop()
        return
    }

    await resetScheduledTasks()

    startCommunityShare()

}

const startCommunityShare = () => {

    sharingClosetsList.value = [...new Set<string>(sharingClosetsList.value.map((item: string) => item.trim()))]

    const taskData = { message: 'handleCommunityShare', limit: limit.value, closetOrder: closetOrder.value, delay: JSON.parse(JSON.stringify({ min: delayRange.value[0], max: delayRange.value[1] })), sharingType: sharingType.value, shareCycles: shareCycles.value, cyclesDelay: JSON.parse(JSON.stringify(cyclesDelay.value)), shareToParty: shareToParty.value, scheduledTasks: JSON.parse(JSON.stringify(scheduledTasks.value)), maxSharesPerDay: maxSharesPerDay.value, sharingClosetsList: JSON.parse(JSON.stringify(sharingClosetsList.value)) }

    //start message listener
    window.addEventListener("message", messagesHandler);

    sendMessage(taskData);

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: delayRange.value,
        allProducts: allProducts.value,

    }


    taskStatus.changeCommunityShareStatus(true)
    taskStatus.changeCommunityShareDaily(sharingType.value === 'scheduled')

    //store latest settings in local storage
    localStorageWrapper.set('communityShareSettings', JSON.stringify(config))
};

const messagesHandler = async (event: any) => {
    if (event.data.message == "communityShareTaskEnded") {

        //the stop request was send by the user
        if (event.data.requestedByUser) {
            taskStatus.changeCommunityShareStatus(false)
            taskStatus.changeCommunityShareDaily(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", messagesHandler);
            await resetScheduledTasks()
            return
        }

        // only continue with the remaining checks if the task is scheduled, otherwise end task
        if (event.data.sharingType != SharingType.Scheduled) {
            taskStatus.changeCommunityShareStatus(false)
            taskStatus.changeCommunityShareDaily(false)
            //stop message listener until the task is started again
            window.removeEventListener("message", messagesHandler);
            return
        }

        const scheduledTaskTime = event.data.scheduledTaskTime
        if (scheduledTaskTime) {
            for (const scheduledTask of scheduledTasks.value) {
                if (scheduledTask.time == scheduledTaskTime) {
                    scheduledTask.processed++;
                    //update local storage
                    const storedData = await localStorageWrapper.get('communityShareSettings')
                    const communityShareSettings = JSON.parse(storedData as string)
                    communityShareSettings.scheduledTasks = scheduledTasks.value
                    localStorageWrapper.set('communityShareSettings', JSON.stringify(communityShareSettings))
                    break
                }
            }
        }

        let allScheduledTasksCompleted = true;
        for (const scheduledTask of scheduledTasks.value) {
            if (scheduledTask.repeat || !scheduledTask.processed) {
                allScheduledTasksCompleted = false;
                break
            }
        }

        taskStatus.changeCommunityShareStatus(!allScheduledTasksCompleted);
        taskStatus.changeCommunityShareDaily(!allScheduledTasksCompleted);

        if (allScheduledTasksCompleted) {
            //stop message listener until the task is started again
            window.removeEventListener("message", messagesHandler);

            await resetScheduledTasks()
        }
    }
    else if (event.data.message == "failedToStartCommunityShareTask") {

        //validations.value.set("sharingMode", [event.data.error]);
        taskStatus.changeCommunityShareStatus(false);
        taskStatus.changeCommunityShareDaily(false);
        //stop message listener until the task is started again
        window.removeEventListener("message", messagesHandler);
        await resetScheduledTasks()
    }
}

const resetScheduledTasks = async () => {
    //resets any scheduled tasks "processed" value to zero

    if (!scheduledTasks.value?.length) return

    for (const scheduledTask of scheduledTasks.value) {
        scheduledTask.processed = 0;
    }
    //update local storage
    const storedData = await localStorageWrapper.get('communityShareSettings')
    const communityShareSettings = JSON.parse(storedData as string)

    if (communityShareSettings) {
        communityShareSettings.scheduledTasks = scheduledTasks.value
        localStorageWrapper.set('communityShareSettings', JSON.stringify(communityShareSettings))
    }

}

//Note: Scheduled tasks cannot be edited (Added or removed) while the share tasks is running.
const addToScheduledTasks = () => {
    if (!scheduledTaskTime.value) {
        validations.value.set("sharingMode", ["Scheduled task time is required"]);
        return;
    }

    const taskFormattedTime = format(scheduledTaskTime.value, "HH:mm")

    if (uniqueScheduledTasksSet.has(taskFormattedTime)) {
        validations.value.set("sharingMode", ["Scheduled task already exists"]);
        return;
    }

    validations.value.set("sharingMode", [])

    const newTask = {
        time: scheduledTaskTime.value?.toString() as string,
        repeat: repeatDaily.value,
        processed: 0,
    }

    scheduledTasks.value.push(newTask);
    uniqueScheduledTasksSet.add(taskFormattedTime);

}

//Note: Scheduled tasks cannot be edited (Add or remove) while the share tasks is running.
const removeScheduledTask = (index: number, time: string) => {
    const taskFormattedTime = format(time, "HH:mm")
    const deletedTask = scheduledTasks.value.splice(index, 1);
    uniqueScheduledTasksSet.delete(taskFormattedTime);
}

const changeSharingMode = () => {
    if (sharingType.value === "scheduled") {
        //initial schedule time to current time +1 minute
        const time = new Date();
        time.setMinutes(time.getMinutes() + 1);
        //scheduledTaskTime.value = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        scheduledTaskTime.value = time
    }
}

const requireConfirmation = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message: 'stopCommunityShareTask' });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass:
            'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass:
            'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    })
}

</script>

<style lang="scss" scoped>
:deep(.p-radiobutton) {
    .p-radiobutton-box {
        border: 1px solid #0070b7;
        border-radius: 50%;
    }
}
</style>
