<template>
    <TabNavigation :is-active="taskStatus.calculateCloset.status" />
    <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">
        <Card class="p-0 bg-gray-100">
            <template #title>
                <div class="flex items-center justify-between">
                    <h6 class="text-base">Closet Calculator <i
                            title="This feature runs instantly and does not get scheduled"
                            class="pi pi-bolt text-blue-500 border-blue-500 border-2 p-[1px] rounded-full ml-1"></i>
                    </h6>
                </div>
            </template>
            <template #content>
                <Card class="p-0 mt-3">
                    <template #content>
                        <div v-if="validations.has('calculateCloset')">
                            <Message severity="error"
                                v-for="(errorMessage, index) of validations.get('calculateCloset')" :keys="errorMessage"
                                icon="null" :closable="false">
                                {{ errorMessage }}
                            </Message>
                        </div>
                        <div class="flex items-center gap-3 mt-4">
                            <div class="flex items-center gap-x-3 w-full">
                                <label for="closetURL" class="font-bold block ">
                                    Closet URL
                                </label>
                                <InputText :placeholder="'https://poshmark.com/closet/closet_owner_username'"
                                    class="flex-grow p-3 border border-[#0070b7] rounded-lg"
                                    v-model="calculateClosetURL" inputId="calculateClosetURL" :useGrouping="true"
                                    :disabled="taskStatus.calculateCloset.status" />
                            </div>
                        </div>
                        <div v-if="closetRevenueDetails.loading" class="flex text-center py-6">
                            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8"
                                fill="var(--surface-ground)" aria-label="Custom ProgressSpinner" />
                        </div>
                        <div v-if="noItemsFound" class="flex text-center">
                            <Message class="w-full" severity="success" icon="null" :closable="false">
                                No items sold in last 30 days
                            </Message>
                        </div>
                        <div class="flex justify-end">
                            <div class="flex justify-end mt-5 gap-x-4">
                                <Button v-if="taskStatus.calculateCloset.status" @click="requireConfirmation('top')"
                                    class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500">
                                    Stop Current Task
                                </Button>
                                <Button v-else
                                    class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
                                    @click="validateCalculateClosetRequest()">
                                    Calculate
                                    <div v-if="!authStore?.hasActivePlan()"
                                        class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2">
                                        <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div v-if="closetRevenueDetails.show" class="mt-4">
                            <h6 class="mt-1"><strong>Duration:</strong> {{ closetRevenueDetails.scanStartDate }} - {{
                                closetRevenueDetails.scanEndDate }}</h6>
                            <h6><strong>Sold Items:</strong> <span class="text-blue-500 text-lg">{{
                                closetRevenueDetails.noOfSoldItems }}</span></h6>
                            <h6><strong>Last Month Revenue:</strong> $<span class="text-blue-500 text-lg">{{
                                closetRevenueDetails.revenue.toLocaleString(undefined, {
                                    minimumFractionDigits:
                                        2, maximumFractionDigits: 2
                                    }) }}</span></h6>
                        </div>
                    </template>
                </Card>
            </template>
        </Card>

        <ConfirmDialog group="positioned"></ConfirmDialog>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useTaskStatusStore } from '@/stores/taskStatus';
import { useConfirm } from 'primevue/useconfirm';
import { log, isValidClosetUrl, scrollToTop } from "@/utils/index";
import { format } from "date-fns"
import { useActiveTab } from '@/stores/activeTab';
import { useAuthStore } from "../stores/auth";
import { useRouter } from 'vue-router';
import TabNavigation from "./TabNavigation.vue"
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Card from 'primevue/card';
import ConfirmDialog from 'primevue/confirmdialog';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import * as Sentry from '@sentry/vue';
import LocalStorageWrapper from '@/classes/LocalStorageWrapper';

const hideUI = ref(true)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore();
const tabView = useActiveTab();
const confirm = useConfirm();
const taskStatus = useTaskStatusStore();
const route = useRouter();
const isCalculateClosetEnabled = ref<boolean>(taskStatus.calculateCloset.status)
const delayRange = ref<number[]>([1, 1]);
const validations = ref(new Map())
const noItemsFound = ref<boolean>(false)
const calculateClosetURL = ref<string>()
const closetRevenueDetails = ref<{
    show: boolean,
    loading: boolean,
    revenue: number,
    noOfSoldItems: number,
    scanStartDate: string,
    scanEndDate: string
}>({ revenue: 0, noOfSoldItems: 0, show: false, loading: false, scanStartDate: '', scanEndDate: '' });


onMounted(async () => {
    //load last used settings if found
    await loadCalculateClosetSettings();
    hideUI.value = false
})

const loadCalculateClosetSettings = async () => {
    try {
        const storedData = await localStorageWrapper.get('calculateClosetSettings')
        const calculateClosetSettings = JSON.parse(storedData as string)

        if (!calculateClosetSettings) return

        closetRevenueDetails.value = calculateClosetSettings.closetRevenueDetails
        closetRevenueDetails.value.loading = closetRevenueDetails.value.loading && taskStatus.calculateCloset.status
        calculateClosetURL.value = calculateClosetSettings.calculateClosetURL
        delayRange.value = calculateClosetSettings.delayRange ?? [1, 1]

        //clear previous calculations if it was stored from previous run
        if (!closetRevenueDetails.value.loading) {
            //Note: this also deletes the closet url used
            localStorageWrapper.remove('calculateClosetSettings')
        }
        else {
            //reset listeners to reflect latest, mounted ClosetCalculatorTab vue
            window.removeEventListener('message', calculateClosetMessagesHandler);
            window.addEventListener('message', calculateClosetMessagesHandler);
        }
    } catch (err) {
        //failed to acccess localStorage
        Sentry.captureException(err);
    }

}


const sendMessage = (msg: any) => {
    window.parent.postMessage(msg, "*");
}

const validateCalculateClosetRequest = () => {
    if (!authStore?.hasActivePlan()) {
        upgradeSubscriptionAlert('top')
        return
    }

    if (taskStatus.calculateCloset.status) return

    log("Validating closet calculator request")

    let messages = []

    if (!delayRange.value[0] || delayRange.value[0] < 0)
        messages.push("Delay should be at least 1 second.")
    else if (!delayRange.value[1] || delayRange.value[1] < delayRange.value[0])
        messages.push("Invalid delay range.");

    if (!calculateClosetURL.value?.trim() || !isValidClosetUrl(calculateClosetURL.value?.trim()))
        messages.push("Invalid closet URL.");


    validations.value.set("calculateCloset", messages);

    if (messages.length) {
        scrollToTop()
        return
    }

    //update and send to extension to start task
    const taskData = { message: 'closetCalculator', calculateClosetURL: calculateClosetURL.value, delay: { min: delayRange.value[0], max: delayRange.value[1] } }

    //start message listener
    window.addEventListener("message", calculateClosetMessagesHandler);

    sendMessage(taskData)

    taskStatus.changeCalculateClosetStatus(true);

    //reseting closet revenue details
    closetRevenueDetails.value.loading = true
    closetRevenueDetails.value.show = false
    closetRevenueDetails.value.revenue = 0
    closetRevenueDetails.value.noOfSoldItems = 0

    //storing current task configuration to load later to the user
    const config = {
        ...taskData,
        delayRange: delayRange.value,
        calculateClosetURL: calculateClosetURL.value,
        closetRevenueDetails: closetRevenueDetails.value,
    }

    //store latest settings in local storage
    localStorageWrapper.set('calculateClosetSettings', JSON.stringify(config))
}

const requireConfirmation = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        rejectClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        acceptClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Keep Task running',
        acceptLabel: 'Stop Task',
        header: 'Are you sure you want to stop this task?',
        message: 'Please confirm to proceed.',
        accept: () => {
            sendMessage({ message: 'stopCalculateClosetTask' });
        }
    });
};

const upgradeSubscriptionAlert = (position: any) => {
    confirm.require({
        group: 'positioned',
        icon: 'pi pi-info-circle',
        position: position,
        acceptClass: 'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
        rejectClass: 'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
        rejectLabel: 'Cancel',
        acceptLabel: 'Subscribe',
        header: 'Upgrade Subscription',
        message: 'Upgrade subscription to enable this feature.',
        accept: () => {
            tabView.changeActiveTab(2)
        }
    });
};

//This will handle messages sent from the extension to the web server
const calculateClosetMessagesHandler = async (event: any) => {
    if (event.data.message == "showCalculatorResult") {
        closetRevenueDetails.value.show = true;
        closetRevenueDetails.value.loading = false;
        closetRevenueDetails.value.revenue = event.data.result;
        closetRevenueDetails.value.noOfSoldItems = event.data.numOfSoldItems;
        closetRevenueDetails.value.scanStartDate = format(event.data.scanStartDate, "MM/dd/yyyy")
        closetRevenueDetails.value.scanEndDate = format(event.data.scanEndDate, "MM/dd/yyyy")
        log(event.data.result, "$", " # of sold items: ", event.data.numOfSoldItems, "api drawer");

        //update storage
        const storedData = await localStorageWrapper.get('calculateClosetSettings')
        const calculateClosetSettings = JSON.parse(storedData as string)

        //store calculations only if the tab is not active
        if (route.currentRoute.value.name !== "closet-calculator") {
            //storing current task configuration to load later to the user
            calculateClosetSettings.closetRevenueDetails = closetRevenueDetails.value
        }

        //store latest settings in local storage
        localStorageWrapper.set('calculateClosetSettings', JSON.stringify(calculateClosetSettings))
    }
    else if (event.data.message == "calculateClosetTaskEnded") {

        //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
        if (event.data.requestedByUser || !event.data.isDaily) {
            taskStatus.changeCalculateClosetStatus(false)
            closetRevenueDetails.value.loading = false;
            //stop message listener until the task is started again
            window.removeEventListener("message", calculateClosetMessagesHandler);
            return
        }
    }
    else if (event.data.message == "failedToStartCalculateClosetTask") {

        //validations.value.set("calculateCloset", [event.data.error]);
        taskStatus.changeCalculateClosetStatus(false);
        closetRevenueDetails.value.loading = false;
        //stop message listener until the task is started again
        window.removeEventListener("message", calculateClosetMessagesHandler);
    }
}

</script>